import {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import Avatar from 'react-avatar'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

// import components
import { useAppSelector, useAppDispatch } from 'hooks'
// import SelectSidebarLanguage from './SelectLanguage'

// import assets
import TravelSummaryIcon from 'assets/images/travel-summary-gray.svg'
import SavingsOpportunityIcon from 'assets/images/savings-opportunity.svg'
import GlReportingIcon from 'assets/images/gl-reporting.svg'
import GlReportingGreyIcon from 'assets/images/gl-reporting-grey.svg'
import ReportingHubIcon from 'assets/images/reporting-hub.svg'
import SupportIcon from 'assets/images/support.svg'
import ActiveTravelSummaryIcon from 'assets/images/travel-summary-blue.svg'
import ActiveSavingsOpportunityIcon from 'assets/images/savings-opportunity-blue.svg'
import ActiveReportingHubIcon from 'assets/images/reporting-hub-blue.svg'
import ActiveSupportIcon from 'assets/images/support-blue.svg'
import NotificationsIcon from 'assets/images/bell.svg'
import ActiveNotificationsIcon from 'assets/images/bell-blue.svg'
import GBTLogo from 'assets/images/GBTLogo-Small.svg'

const App = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const full_name = useAppSelector((state) => state.userInfo.userAuthInfo?.full_name)
  // const GLRepotingAccess = 'Yes'
  let isGLUser = false;
  // if(GLRepotingAccess === 'Yes') {
  //   isGLUser = false;
  // }
  // const userRole = useAppSelector((state) => state.userInfo.userAuthInfo?.roles)

  const currentYear = format(new Date(), 'yyyy')
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const routes = [
    {
      title: t('travelSummaryHeader'),
      uri: '/travel-summary',
      icon: TravelSummaryIcon,
      activeIcon: ActiveTravelSummaryIcon,
      disabled: false,
    },
    {
      title: t('savingsOpportunityHeader'),
      uri: '/savings-opportunity',
      icon: SavingsOpportunityIcon,
      activeIcon: ActiveSavingsOpportunityIcon,
      disabled: false,
    },
    ...(isGLUser? [{
      title: 'GL Reporting',
      uri: '/gl-reporting',
      icon: GlReportingGreyIcon,
      activeIcon: GlReportingIcon,
      disabled: false,
    },] : []),
    {
      title: t('reportingHubHeader'),
      uri: '/reporting-hub',
      icon: ReportingHubIcon,
      activeIcon: ActiveReportingHubIcon,
      disabled: false,
    }
  ]

  const userLinks = [
    {
      title: t('notifications'),
      uri: '/notifications',
      icon: NotificationsIcon,
      activeIcon: ActiveNotificationsIcon,
      disabled: true,
    },
    {
      title: t('support'),
      uri: '/support',
      icon: SupportIcon,
      activeIcon: ActiveSupportIcon,
      disabled: true,
    },
    {
      title: t('username'),
      uri: '',
      disabled: false,
    },
  ]

  return (
    <>
    {!sidebarVisible &&
    <button
      className="md:hidden absolute top-0 left-0 ml-1 text-xl cursor-pointer text-blue"
      onClick={() => setSidebarVisible(!sidebarVisible)}
    >
      &#9776;
    </button>}
    <div className={`w-[6.25rem] bg-lightest-blue h-full px-2 z-10 absolute top-0 bottom-0 md:relative duration-300 border-r-[1.5px] border-l-[1px] text-sm text-gray-9 ${sidebarVisible ? 'mobile-sidebar-visible' : 'mobile-sidebar-invisible'}`}>
      <ReactTooltip style={{ backgroundColor: "#006FCF" }}
        arrowColor="#006FCF"
        border="#006FCF"
      />
      {sidebarVisible &&
      <button
        className="md:hidden absolute top-0 right-0 mr-1 text-xl cursor-pointer text-blue"
        onClick={() => setSidebarVisible(!sidebarVisible)}
      >
        &#9776;
      </button>}
      {/* Start : App Logo */}
      <div className="w-full h-[15%] md:h-1/10">
        <Link to="/" className="flex items-center justify-center w-full h-full">
            <h1 className="font-bold leading-6 tracking-normal text-xl text-dark-blue text-center">
              Wells Fargo
            </h1>
        </Link>
      </div>
      {/* End : App Logo */}

      {/* Start: Routes  */}
      <div className="w-full h-[45%] md:h-5/10">
        <div className="flex flex-col justify-center w-full space-y-6 h-10/10">
          {routes.map((route) => {
            const active = pathname.includes(route.uri)
            return (
              <Link
                key={route.title}
                to={`${route.disabled ? '#' : `${route.uri}`}`}
                className={`text-center w-full text-sm text-gray-300 cursor-pointer hover:bg-light-white ${
                  route.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                data-tip={route.title}
                data-tooltip-delay-hide={3000}
              >
                <img
                  src={active ? route.activeIcon : route.icon}
                  className={`w-[45%] md:w-3/5 m-auto mb-2 ${route.disabled ? 'opacity-30' : ''}`}
                  alt={route.title}
                />
                <span
                  className={`font-medium text-center ${
                    active ? 'text-blue' : 'text-gray-9'
                  }`}
                >
                  {route.title}
                </span>
              </Link>
            )
          })}
        </div>
      </div>
      {/* End: Routes  */}

      {/* Start: UserLinks  */}
      <div className="flex flex-col justify-end w-full space-y-6 h-[25%] md:h-2/10">
        {userLinks.map((link) => {
          const active = pathname === link.uri
          return (
            <span
              className="flex flex-row items-center justify-between w-full font-medium"
              key={link.title}
            >
              {
                <>
                  <Link
                    to={`${link.disabled ? '#' : `${link.uri}`}`}
                    data-tip={
                      link.title === 'UserName' ? full_name : link.title
                    }
                    data-tooltip-delay-hide={3000}
                    className={`flex flex-row items-center w-full justify-center ${
                      link.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                  >
                    {link.title === t('username') ? (
                      <span className="flex flex-row items-center justify-between">
                        <Avatar
                          color="#006FCF"
                          round
                          size="35"
                          textSizeRatio={3}
                          name={full_name}
                        />
                      </span>
                    ) : (
                      <>
                        <img
                          src={active ? link.activeIcon : link.icon}
                          alt={link.title}
                          // className={link.disabled ? 'opacity-30' : ''}
                          height="25"
                          width="25"
                        />
                      </>
                    )}
                  </Link>
                </>
              }
            </span>
          )
        })}
      </div>
      {/* End: UserLinks  */}
      {/* Start: Footer  */}
      <div className="flex flex-col items-center justify-center w-full h-[15%] md:h-2/10">
        <img
          src={GBTLogo}
          alt="American Express Global Business Travel"
        />

        <p className="pt-1 text-gray-3 text-[.5rem] leading-[.6rem]">
          {`(C) ${currentYear}`}
        </p>
      </div>
      {/* End: Footer  */}
    </div>
    </>
  )
}
export default App
